export default function({ url, name, score, reviewCount }) {
  return `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": "${url}#Product",
    "name": "${name}",
    "aggregateRating": {
      "@type": "AggregateRating",
      "bestRating": "5",
      "worstRating": "1",
      "ratingValue": "${score}",
      "reviewCount": "${reviewCount}"
    }
  }`
}
