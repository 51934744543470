import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import cx from 'classnames'
import Head from 'next/head'
import dynamic from 'next/dynamic'

import Header from './Header'
import Footer from './Footer'
import withInfoHOC from 'hocs/withInfoHOC'
import withMainBannerHOC from 'hocs/withMainBannerHOC'
import classNames from './layout.module.scss'
import CartProvider from 'providers/CartProvider'
import gtmScript from 'common/utils/gtmScript'


const CartModal = dynamic(() => import('./CartModal'), { ssr: false })

AppLayout.propTypes = {
  children: PropTypes.node,
  withCover: PropTypes.bool,
  isBannerVisible: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  isFooterEnabled: PropTypes.bool,
  isSchoolFinder: PropTypes.bool,
  withoutCart: PropTypes.bool,
  bannerData: PropTypes.object,
  toggleBanner: PropTypes.func.isRequired,
}

AppLayout.defaultProps = {
  children: null,
  withCover: false,
  isBannerVisible: false,
  isSchoolFinder: false,
  withoutCart: false,
  isHeaderEnabled: true,
  isFooterEnabled: true,
  bannerData: {},
}

function AppLayout({ children, withCover, isBannerVisible, toggleBanner, bannerData, isHeaderEnabled, isFooterEnabled, isSchoolFinder, withoutCart }) {
  return (
    <Fragment>
      <Head>
        <script
          id="gtm-script"
          dangerouslySetInnerHTML={{ __html: gtmScript(process.env.GTM_ID) }}
        />
      </Head>
      <CartProvider>
        <Header
          withCover={withCover}
          toggleBanner={toggleBanner}
          isBannerVisible={isBannerVisible}
          bannerData={bannerData}
          isHeaderEnabled={isHeaderEnabled}
          isSchoolFinder={isSchoolFinder}
        />
        <main className={cx(isSchoolFinder && classNames.schoolFinder)}>

          {children}
          <CartModal withoutCart={withoutCart} />
        </main>
        {isFooterEnabled && (
          <Footer />
        )}
      </CartProvider>
    </Fragment>
  )
}

export default compose(
  withInfoHOC,
  withMainBannerHOC,
)(AppLayout)
