import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { withRouter } from 'next/router'
import HeaderView from './HeaderView'


export default compose(
  withRouter,
  connect(state => ({
    data: get(state, 'cmsInfo.data.header.menu.sections', []),
  }))
)(HeaderView)
