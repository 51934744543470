import {
  Accordion,
  AccordionWithImages,
  AlbumGalleryCard,
  AnimatedLink,
  CardWithVideo,
  Contact,
  Content,
  DetailCard,
  FlipBoxes,
  HeroImage,
  HeroImageAnimated,
  HeroImageFilter,
  HeroImageSimple,
  OneColumnCarousel,
  TwoColumnCarousel,
  HeroImageWithTemplate,
  HiddenCollapsibleText,
  ImageGrid,
  ImageGridPortrait,
  ImageGridSquare,
  ImageGridWithFilter,
  Masonry,
  ExperienceMasonry,
  NewsCarousel,
  PortfolioCarousel,
  PortfolioDetailCollapsible,
  Quote,
  RotatingText,
  SplitTextCard,
  TestimonialImages,
  TestimonialShowcase,
  ProductReviews,
  Map as MapBlock,
  Forms,
  GoogleAnalytics,
  HeroWithText,
} from 'common/widgets/blocks'
import { TerminWidget } from 'common/widgets'


const blocks = {
  faq_accordion: Accordion,
  leisure_tips_split_card: AccordionWithImages,
  album_gallery_card: AlbumGalleryCard,
  animated_link_split_card: AnimatedLink,
  video_split_card: CardWithVideo,
  video_split_card_with_score: CardWithVideo,
  contact_form: Contact,
  portfolio_detail_card: DetailCard,
  service_flip_boxes: FlipBoxes,
  three_line_hero_image: HeroImage,
  home_page_hero_image_with_quick_filter: HeroImage,
  filter_hero_image: HeroImageFilter,
  hero_image_advertising_animated: HeroImageAnimated,
  hero_image_advertising_simple: HeroImageSimple,
  three_line_hero_image_with_seo_template: HeroImageWithTemplate,
  collapsible_text: HiddenCollapsibleText,
  one_column_image_carousel: OneColumnCarousel,
  two_column_image_carousel: TwoColumnCarousel,
  destination_masonry_grid: ImageGrid,
  school_grid: ImageGridPortrait,
  image_grid_square: ImageGridSquare,
  image_grid_with_filter: ImageGridWithFilter,
  news_masonry: Masonry,
  experience_masonry_grid: ExperienceMasonry,
  news_specials_carousel: NewsCarousel,
  portfolio_carousel: PortfolioCarousel,
  portfolio_detail_card_collapsible: PortfolioDetailCollapsible,
  quote: Quote,
  rotating_split_card: RotatingText,
  three_fonts_split_card: SplitTextCard,
  hover_testimonial_images: TestimonialImages,
  testimonial_showcase: TestimonialShowcase,
  content: Content,
  map: MapBlock,
  product_reviews_list: ProductReviews,
  form: Forms,
  ga_tracking_code_block: GoogleAnalytics,
  appointment_block: TerminWidget,
  quote_section_block: HeroWithText,
}

export default blocks
