import { useCallback, useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Link from 'next/link'

import { Container, Button } from 'common/widgets'
import classNames from './layout.module.scss'
import SelectInput from 'common/forms/inputs/SelectInput'
import Hamburger from './widgets/Hamburger'
import NavItem from './widgets/NavItem'
import { useTranslations } from 'common/language'
import logoBoa from '@img/logo.svg'
import logoBoaFr from '@img/logo-fr.svg'
import Banner from './BannerView'
import CampaignBanner from './CampaignBannerContainer'
import { moveBackAfterClose, addFixedToBody } from 'common/utils/modalScroll'


HeaderView.propTypes = {
  data: PropTypes.array,
  bannerData: PropTypes.object,
  withCover: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool.isRequired,
  toggleBanner: PropTypes.func.isRequired,
  isBannerVisible: PropTypes.bool.isRequired,
  isSchoolFinder: PropTypes.bool,
}

HeaderView.defaultProps = {
  withCover: false,
  data: [],
  bannerData: {},
  isSchoolFinder: false,
}

const LANGUAGES_MENU = [
  { title: 'DE', value: 'de' },
  { title: 'FR', value: 'fr' },
]

export default function HeaderView({ data, toggleBanner, isBannerVisible, withCover, bannerData, isHeaderEnabled, isSchoolFinder }) {
  const [isActive, setStatus] = useState(false)
  const [withGradient, setGradient] = useState(true)
  const toggleStatus = useCallback((value) => {
    setStatus(!isActive)
  }, [isActive])
  const handleScroll = useCallback((_) => setGradient(document.body.getBoundingClientRect().top === 0), [setGradient])
  useEffect(() => {
    if(typeof window !== 'undefined') {
      if(withCover && !isBannerVisible) {
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      }
    }
  }, [withCover, isBannerVisible])
  useEffect(() => {
    isActive
      ? addFixedToBody()
      : moveBackAfterClose()
  }, [isActive])
  const { languageCode } = useTranslations()
  const handleChangeLanguage = useCallback(lang => {
    if(typeof window !== 'undefined') {
      const path = window.location.href.replace(window.location.origin, '')
      const origin = window.location.origin
      let url = origin + path
      const links = document.getElementsByTagName('link')
      for(const link of links) {
        if(link.hreflang === lang) {
          url = link.href + window.location.search
        }
      }
      window.location.assign(url)
    }
  }, [])
  const navItems = useMemo(() => data.map(item => (
    <NavItem
      data={item}
      key={item.id}
      isActiveCart={isActive}
      toggleStatus={toggleStatus}
    />
  )), [data, isActive, toggleStatus])
  const languageList = useMemo(_ => LANGUAGES_MENU.map(language => (
    <Button
      key={language.value}
      onClick={handleChangeLanguage.bind(null, language.value)}
      type={language.value === languageCode ? 'primary' : 'primary-outlined'}
      className={classNames.buttonLanguageMobile}
    >
      {language.title}
    </Button>
  )), [handleChangeLanguage, languageCode])
  const homeUrl = useMemo(() => {
    return `${process.env.ORIGIN_URL}/${['default', 'en', 'de'].includes(languageCode) ? '' : languageCode}`
  }, [languageCode])
  return (
    <div
      className={cx(classNames.header, withCover && !isBannerVisible && classNames.cover, (!withGradient || isActive) && classNames.clear)}
      id="header"
    >
      {isHeaderEnabled && (
        <div className={classNames.headerWrapper}>
          <Container
            size="xl"
            onMouseEnter={() => setGradient(false)}
            onMouseLeave={handleScroll}
          >
            <div className={classNames.row}>
              <div>
                <Link href={homeUrl} locale={['default', 'en', 'de'].includes(languageCode) ? false : languageCode} prefetch={false}>
                  <a>
                    <img
                      alt="Boalingua Logo"
                      src={['default', 'en', 'de'].includes(languageCode) ? logoBoa : logoBoaFr}
                      className={classNames.headerLogo}
                    />
                  </a>
                </Link>
              </div>
              <div className={classNames.headerCol}>
                <Hamburger isActive={isActive} toggleStatus={toggleStatus} />
                <div className={classNames.navBar}>
                  <ul className={classNames.navMenu}>
                    {navItems}
                  </ul>
                  <a href={['default', 'en', 'de'].includes(languageCode) ? 'tel:0800 33 55 88' : 'tel:0800 31 03 20'} className={classNames.phoneLink} aria-label="call Boa Lingua">
                    <FontAwesomeIcon className={classNames.indicator} icon="phone" />
                  </a>
                  <div className={classNames.mobileLanguageWrapper}>
                    {languageList}
                  </div>
                </div>
                <SelectInput
                  options={LANGUAGES_MENU}
                  labelKey="title"
                  valueKey="value"
                  value={['default', 'en'].includes(languageCode) ? 'de' : languageCode}
                  className={classNames.languageSelect}
                  onChange={handleChangeLanguage}
                  menuPortalTarget={typeof window !== 'undefined' ? document.getElementById('header') : undefined}
                  menuPosition="fixed"
                  instanceId="header-lang-select"
                  aria-label="Select Language"
                />
              </div>
            </div>
          </Container>
        </div>
      )}
      {(bannerData.title || bannerData.content)
        ? (isBannerVisible && <Banner toggleBanner={toggleBanner} data={bannerData} isSchoolFinder={isSchoolFinder} />)
        : <CampaignBanner isSchoolFinder={isSchoolFinder} />
      }
    </div>
  )
}
