import { useMemo, useState, useCallback, useEffect } from 'react'
import { ResourceType } from '@ds-frontend/resource'
import { useRouter } from 'next/router'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Button, Typography } from 'common/widgets'
import scrollSmoothTo from 'common/utils/scrollSmoothTo'
import classNames from './layout.module.scss'


CampaignBannerView.propTypes = {
  campaignBanner: ResourceType.isRequired,
  isSchoolFinder: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

CampaignBannerView.defaultProps = {
  data: {},
  banners: [],
  className: '',
}

export default function CampaignBannerView({ campaignBanner, isSchoolFinder, className }) {
  const [ isBannerVisible, setVisible ] = useState(false)
  const { push, asPath } = useRouter()
  const toggleBanner = useCallback(() => {
    sessionStorage.setItem('campaign_banner_' + campaignBanner.id, campaignBanner.updated)
    setVisible(false)
  }, [campaignBanner])
  const isDefaultBannerVisible = useMemo(() =>
    (campaignBanner?.title || campaignBanner?.content) && typeof sessionStorage !== 'undefined' && sessionStorage.getItem('campaign_banner_' + campaignBanner.id) !== campaignBanner.updated,
  [campaignBanner])

  const handleClick = useCallback(e => {
    const a = e.target.closest('a')
    if(a) {
      e.preventDefault()
      const anchorURL = new URL(a.href)
      const routeURL = new URL(asPath, process.env.BACKEND_URL)
      if(anchorURL.hash === '#prices' && anchorURL.pathname.endsWith(routeURL.pathname)) {
        scrollSmoothTo('prices')
      } else {
        push(a.href)
      }
    }
  }, [])

  useEffect(() => {
    setTimeout(() => setVisible(isDefaultBannerVisible), 100)
  }, [isDefaultBannerVisible])

  if(!campaignBanner || !isBannerVisible) {
    return null
  }

  return (
    <div className={cx(classNames.bannerWrapper, isSchoolFinder && classNames.schoolFinder, className)}>
      <Typography
        variant="h4"
        tag="p"
        className={classNames.bannerTitle}
      >
        {campaignBanner?.title}
      </Typography>
      <Typography
        className={classNames.bannerText}
        tag="div"
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: campaignBanner?.content }}
      />
      <Button
        type="icon"
        icon="times"
        className={classNames.closeIcon}
        onClick={toggleBanner}
      />
    </div>
  )
}
