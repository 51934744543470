import cx from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Button, Typography } from 'common/widgets'
import classNames from './layout.module.scss'


BannerView.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  toggleBanner: PropTypes.func,
  isSchoolFinder: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

BannerView.defaultProps = {
  data: {},
  toggleBanner: noop,
  className: '',
}

export default function BannerView({ data, toggleBanner, isSchoolFinder, className }) {
  return (
    <div className={cx(classNames.bannerWrapper, isSchoolFinder && classNames.schoolFinder, className)}>
      <Typography
        variant="h4"
        tag="p"
        className={classNames.bannerTitle}
      >
        {data.title}
      </Typography>
      <Typography
        className={classNames.bannerText}
        tag="div"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      {toggleBanner && (
        <Button
          type="icon"
          icon="times"
          className={classNames.closeIcon}
          onClick={toggleBanner}
        />
      )}
    </div>
  )
}
